import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { GTM_ID } from '../shared/env';
import '../styles/globals.scss';

function BusinessHome({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <Script
                    id="ga-script"
                    defer={true}
                    dangerouslySetInnerHTML={{
                        __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GTM_ID}');`,
                    }}
                />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
                {!!pageProps.canonicalUrl && <link rel="canonical" href={pageProps.canonicalUrl} />}
            </Head>
            <Component {...pageProps} />
        </>
    );
}

export default BusinessHome;
